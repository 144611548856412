import HeaderCom from '../../../components/Header.vue';

export default {
  name: 'print',
  components: {
    HeaderCom
  },
  data() {
    return {
      total: 0, // 总条目数
      current: 1, // 当前页
      pageSize: 10, // 每页显示数量
      loading: false,
      tabColumns: [
        { title: '序号', type: 'index', width: 50, align: 'center' },
        { title: '准考证号', key: 'examNum', align: 'center' },
        { title: '考试科目', key: 'subject', align: 'center' },
        { title: '考场号', key: 'roomNum', align: 'center', tooltip: true },
        { title: '考试地点', key: 'adress', align: 'center', tooltip: true },
        { title: '考试开始时间', key: 'startTime', align: 'center' },
        { title: '考试结束时间', key: 'endTime', align: 'center' },
        { title: '姓名', key: 'name', align: 'center' },
        { title: '性别', key: 'sex', align: 'center' },
        { title: '身份证号码', key: 'idcard', align: 'center' },
        { title: '联系电话', key: 'phone', align: 'center' },
        { title: '测试级别', key: 'level', align: 'center' },
        { title: '工作单位', key: 'unit', align: 'center' },
      ],
      tabData: [
        {
          examNum: '18',
          subject: '考试科目',
          roomNum: '12',
          adress: '考试地点',
          startTime: '2020-09-07',
          endTime: '2020-09-07',
          name: '张三',
          sex: '男',
          idcard: '654654654654',
          phone: '13555555555',
          level: '级别',
          unit: '工作单位'
        }],
    }
  },
  mounted() {

  },
  methods: {

  }
}